.header {
    color: #fff;
}
.header a {
    color: #fff;
    text-decoration: none;
    padding-bottom: 2px;
}

.header ul {
    margin: 0;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.header li {
    list-style-type: none;
    padding-top: 0;
    padding: 1em;
}

.header li:nth-last-child(2) {
    margin-left: auto;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #eee;
}

.hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #eee;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
