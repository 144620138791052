.app {
    /* background: #0e081a; */
    background: #231f21;
    position: relative;
    height: 100vh;
    width: 100vw;
}

.splash-screen {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    min-height: 100%;
    min-width: 100%;
}

.actual-text {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    min-height: 100%;
    min-width: 100%;
    background: rgba(0, 0, 0, 0.45);
}

.banner-cont {
    color: #fff;
    margin: auto;
    /* margin-top: 7vh; */
    margin-left: 10%;
    font-family: "VT323";
}

.event-button {
    background-color: #444444;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.discord-button {
    background-color: #7289da;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.discord-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.banner-cont h1 {
    font-size: 10vh;
}

.content-cont {
    width: 45vw;
    /* margin: auto; */
    margin-left: 10%;
    color: #fff;
}

.content-cont a {
    color: #fff;
    text-decoration: none;
}

.content-google-cloud-logo {
    height: 1em;
    position: relative;
    bottom: -0.3em;
}

.content-cont p {
    font-size: 2.2vh;
}

@media only screen and (max-width: 800px) {
    .splash-screen {
        display: none;
    }

    .banner-cont {
        margin-left: 1em;
        margin-right: 1em;
    }

    .app {
        width: auto;
        height: auto;
        min-height: 100vh;
    }

    .actual-text {
        position: relative;
        min-height: 100vh;
        min-width: 100vw;
    }

    .content-cont {
        width: unset;
        margin-left: 1em;
        margin-right: 1em;
    }
}
